import React, {Component} from 'react';


import app_background_1 from '../../assets/images/app-background-1.jpg';

import './ServiceComponent.scss';

class ServiceComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className="container text-container col-lg-6">
                    {this.props.title}
                    <hr className="services-divider"></hr>
                    {this.props.desc}
                </div>
                <div className="container col-lg-6 image-container" style={this.props.style}>
                    <img src={this.props.src}></img>
                </div>
            </div>
        )
    }
}

export default ServiceComponent;