import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';

import './SectionButton.scss';

class SectionButton extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Button
                variant="outline-light"
                className="section-button"
                href={this.props.page}
            >
                Scopri di più
            </Button>
        )
    }
}

export default SectionButton;