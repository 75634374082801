import React, {Component} from 'react';

import './Aphorism.scss';

class Aphorism extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="aphorism-container">
                <p className="aphorism">{this.props.aphorism}</p>
                <p className="author">{this.props.author}</p>
                <hr></hr>
            </div>
        )
    }
}

export default Aphorism;