import React, {Component} from 'react';

import Button from 'react-bootstrap/Button';

import './GoToContact.scss';

class GoToContact extends Component {
    render() {
        return (
            <div className="container contact-us-section">
                <div className="container contact-us-container">
                    <h1>contattaci per un preventivo</h1>
                    <Button
                        variant="outline-dark"
                        className="btn-contact"
                        href="#/Contatti"
                    >
                        CONTATTACI
                    </Button>
                </div>
            </div>
        )
    }
}

export default GoToContact;