import React, { Component } from 'react';

import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contatti from './components/Contatti';
import Footer from './components/Footer';
import './App.scss';


import {
    BrowserRouter as Router,
    Switch,
    withRouter,
    Route,
    HashRouter
} from 'react-router-dom';
import AppServices from './components/services/AppServices';
import WebServices from './components/services/WebServices';
import GraphicProject from './components/services/GraphicProject';
import MarketingCommunication from './components/services/MarketingCommunication';
import Administrative from './components/services/Administrative';

class App extends Component {
  render() {
    
    return (
      <HashRouter>
        <div className="container website-body">
          <div className="row navbar-row">
            <Navbar/>
          </div>
          <div className="row">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/About" component={About} />
                <Route path="/Contatti" component={Contatti}/>
                <Route path="/services/AppServices" component={AppServices}/>
                <Route path="/services/WebServices" component={WebServices}/>
                <Route path="/services/GraphicProject" component={GraphicProject} />
                <Route path="/services/MarketingCommunication" component={MarketingCommunication} />
                <Route path="/services/Administrative" component={Administrative} />
              </Switch> 
          </div>
          <div className="row footer-row">
            <Footer/>
          </div>      
        </div>               
      </HashRouter>      
    )
  }
}

export default App;
