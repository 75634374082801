import React, { Component } from 'react';

import {
    BrowserRouter as Router
} from 'react-router-dom'
    
import './Services.scss';
import Aphorism from '../Aphorism';
import GoToContact from "../GoToContact";
import ServiceComponent from './ServiceComponent';


import icon1 from '../../assets/images/tech_icons/administrative-icon1.png';
import icon2 from '../../assets/images/tech_icons/administrative-icon2.png';
import icon3 from '../../assets/images/tech_icons/administrative-icon3.png';
import icon4 from '../../assets/images/tech_icons/administrative-icon4.png';

import background_1 from '../../assets/images/admministrativo-bg-1.jpg';
import background_2 from '../../assets/images/admministrativo-bg-2.jpg';
import background_3 from '../../assets/images/admministrativo-bg-3.jpg';

class Administrative extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <Router>
                <div className="services-title-container">
                    <h1>supporto amministrativo</h1>
                </div>
                <Aphorism
                    aphorism='"Chi conosce tutte le risposte, non si è fatto tutte le domande"'
                    author="Confucio"
                ></Aphorism>
                <ServiceComponent
                    title={<h3>costituzione societaria</h3>}
                    desc={
                        [
                            <p>Grazie alla collaborazione con Synago srl, società che offre servizi alle aziende, Antea supporta sia coloro che intendono avviare un’attività attraverso l’apertura di una partita iva, sia chi ha già un’impresa e necessita di un supporto amministrativo e gestionale.</p>,
                            <br></br>,
                            <p>Tra i servizi che Antea offre in ambito di costituzione societario vi sono le consulenze ad hoc per definire la forma più conveniente di impresa, l’individuazione del codice ATECO e l’apertura della partita iva presso l’Agenzia delle Entrate.</p>
                        ]
                    }
                    src={background_1}
                    className="row services-container-left"
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>tenuta dei bilanci</h3>}
                    desc={
                        [
                            <p>Il bilancio d’esercizio permette, oltre agli obblighi di legge, di avere una rendicontazione analitica dell’andamento aziendale e si rivela uno strumento utilissimo per la pianificazione lavorativa.</p>,
                            <br></br>,
                            <p>Oltre ad essere redatto con chiarezza “deve rappresentare in modo veritiero e corretto la situazione patrimoniale e finanziaria della società e il risultato economico dell’esercizio” (art 2423 Codice Civile).</p>,
                            <br></br>,
                            <p>Tramite la collaborazione con Synago, Antea offre servizi fondamentali come la stesura del bilancio, la tenuta della contabilità e il supporto nella gestione della fatturazione elettronica.</p>
                        ]
                    }
                    src={background_2}
                    className="row services-container-right"
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>gestione degli adempimenti fiscali</h3>}
                    desc={
                        [
                            <p>Dalla ditta individuale alla società più complessa e strutturata, qualsiasi realtà aziendale deve confrontarsi con infiniti adempimenti annuali e una burocrazia persistente, sacrificando tempo ed energia per la compilazione di innumerevoli pratiche.</p>,
                            <br></br>,
                            <p>Per questo motivo offriamo un servizio completo analizzando ogni aggiornamento in materia ed informando con largo anticipo sulle scadenze degli adempimenti e sui relativi costi.</p>
                        ]
                    }
                    src={background_3}
                    className="row services-container-left"
                ></ServiceComponent>


                <div className="row services-container-right">
                                     
                    <div className="container text-container col-lg-6 ">
                        <h3>Technical</h3>
                        <hr className="services-divider"></hr>   
                        <ul>
                            <li>Apertura partita iva</li>
                            <br></br>
                            <li>Costituzione societaria</li>
                            <br></br>
                            <li>Stesura del bilancio</li>
                            <br></br>
                            <li>Tenuta della contabilità</li>
                            <br></br>
                            <li>Supporto nella gestione della fatturazione elettronica</li>
                            <br></br>
                            <li>Gestione degli adempimenti fiscali</li>
                            
                        </ul>
                    </div>
                    
                    <div className="container col-lg-6 icons-table icons-table--3cols">
                        <div className="icons-table-cell">
                            <img src={ icon1 }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ icon2 }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ icon3 }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <img src={ icon4 }></img>
                        </div>                        
                        <hr className="icons-divider"></hr>
                    </div>   
                </div>

                <GoToContact/>
            </Router>
        )
    }
}

export default Administrative;