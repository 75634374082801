import React, {Component} from 'react';

import "./WorkerCard.scss";

class WorkerCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container worker-section">
                <div className="row">
                    <div className="worker-container container">
                        <div className="about-name">
                            <h6>{this.props.name}</h6>
                        </div>
                        <hr className="worker-hr"></hr>
                        <div className={this.props.className}>
                            <img className="about-personal-img col-md-3" src={this.props.profile_img}></img>
                            <p className="about-personal-desc col-md-9">{this.props.desc}</p>                           
                        </div>
                    </div>                    
                </div>
            </div> 
        )
    }
}

export default WorkerCard;