import React, { Component } from 'react';
import {
  BrowserRouter as Router,
    Route,
    Switch,
    Link,
    NavLink,
    withRouter
} from "react-router-dom";

import { Dropdown, Menu } from 'semantic-ui-react';

import Home from './Home';
import About from './About';
import Contatti from './Contatti';
import AppServices from './services/AppServices';
import WebServices from './services/WebServices';
import GraphicProject from './services/GraphicProject';
import MarketingCommunication from './services/MarketingCommunication';
import Administrative from './services/Administrative';


import './Navbar.scss';
import BurgerMenu from './BurgerMenu';
import InlineMenu from './InlineMenu';

import logo from '../assets/images/logo.svg';

class Navbar extends Component {

    render() {

        let services = [
            '/services/AppServices',
            '/services/WebServices',
            '/services/GraphicProject',
            '/services/MarketingCommunication',
            '/services/Administrative'
        ]


        
        return (
            
                <header>
                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand" href="/">
                            <img style={{width: "200px", height:"76px"}} src={logo} alt="logo"></img>
                        </a>

                        <BurgerMenu/>

                        <InlineMenu/>

                    </nav>
                </header>        
        )
    }
}

export default Navbar;