import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    HashRouter
} from 'react-router-dom'
    
import './services/Services.scss';
import './About.scss';
import Aphorism from './Aphorism';
import GoToContact from "./GoToContact";
import WorkerCard from "./WorkerCard";

import lollo from '../assets/images/lollo.png';
import tom from '../assets/images/tom.png';
import enam from '../assets/images/enam.png';


class About extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div>
                <div className="services-title-container">
                    <h1>tante soluzioni, un unico obiettivo</h1>
                </div>
                <Aphorism
                    aphorism='"La mente è come un paracadute: funziona solo quando è aperta"'
                    author="Thomas Dewar"
                ></Aphorism>
                <div className="row">
                    <div className="container col-md-11 about-text-container">
                        <p>
                            Antea nasce dall’idea di <strong>aiutare persone ed aziende nel loro percorso di digitalizzazione e rinnovamento</strong>.
                        </p>
                        <br></br>
                        <p>
                            Inizialmente nata come software house, ci siamo resi conto, dopo avere ascoltato attivamente le esigenze 
                            dei clienti e del mercato, della necessità di offrire un <strong>pacchetto di sviluppo a 360 gradi</strong>, non limitandoci 
                            alla creazione di app e siti web, ma elaborando interi progetti dall’inizio alla fine, dallo sviluppo di software, 
                            all’ideazione di strategie di marketing e comunicazione fino all’erogazione di servizi amministrativi atti 
                            ad aprire una startup.
                        </p>
                        <br></br>
                        <p>
                            Certo, solo in Italia ci sono migliaia di aziende che sviluppano app, creano strategie di marketing o 
                            offrono assistenza burocratica; ciò che rende <strong>ANTEA unica nel suo genere</strong> e la differenzia dai competitor è proprio 
                            la sua capacità, grazie alla sua <strong>estesa rete di professionisti</strong>, di offrire questi servizi in un unico pacchetto, 
                            facilitando lo sviluppo del progetto e consentendo di risparmiare tempo e denaro. 
                        </p>
                        <br></br>
                        <p>
                            Noi fondatori di Antea siamo tre ragazzi fermamente convinti che una specializzazione da sola non basti più se 
                            non interagisce immediatamente con tutte le altre realtà. Certo, fare tutto da soli sarebbe impossibile e non 
                            garantirebbe la <strong>qualità</strong> che desideriamo assicurare ai nostri clienti per ogni singolo progetto.
                        </p>
                        <br></br>
                        <p>
                            Da questo presupposto nasce l’idea  della creazione di una <strong>rete di persone</strong>, <strong>competenze</strong> e <strong>relazioni</strong>, 
                            imprescindibile per sopravvivere in un contesto in cui il continuo sviluppo tecnologico e le barriere burocratiche 
                            la fanno da padroni.
                        </p>
                        <br></br>
                        <p>
                            ANTEA si avvale dunque di una estesa rete di professionisti del campo della digitalizzazione, della tecnologia, 
                            della comunicazione e dell’amministrazione pronti a fare fronte ad ogni necessità.
                        </p>
                        <br></br>
                        <p>
                            Questo è dunque ciò che siamo e ciò che facciamo e, più nello specifico, di seguito le nostre presentazioni:
                        </p>
                    </div>

                </div>
                <WorkerCard
                    profile_img={enam}
                    name="muhammad enamul huq sarkar"
                    desc="Enam per gli amici, classe 1986 e membro anziano del gruppo, è un ingegnere
                        informatico di comprovata esperienza internazionale più che decennale.
                        Esperto di architetture front-end e back-end, ha collaborato con le più importanti 
                        società di informatica di Milano, tra cui Boole Server e Armadillo."
                    className="about-personal-container-left"
                ></WorkerCard>
                <WorkerCard
                profile_img={lollo}
                    name="lorenzo bragato"
                    desc="Classe 1995,si è laureato in Storia presso l'Università degli Studi di Milano, 
                        materia che gli ha fornito capacità di analisi qualitative e di strategia. 
                        Ha traslato questa sua passione nel mondo del marketing e della comunicazione, lavorando 
                        per prestigiose multinazionali come Vodafone, Air Liquide e OMG. Il suo motto è 
                        “Memento Audere Semper”, “ricordati di osare sempre”."
                    className="about-personal-container-right"
                ></WorkerCard>  
                
                <WorkerCard
                profile_img={tom}
                    name="tommaso strano"
                    desc="Nato nel 1992, è praticamente cresciuto nello Studio Abbiezzi e Strano, studio di commercialisti di 
                        famiglia, luogo in cui ha sviluppato poliedriche esperienze nel campo amministrativo. 
                        Amante dell'informatica, ha coniugato questa passione con il proprio lavoro, implementando 
                        sistemi gestionali per ottimizzare il rendimento dello Studio."
                    className="about-personal-container-left"
                ></WorkerCard> 
                <div className="row">
                    <div className="container col-md-11 about-text-container">
                        <h5
                            style= {
                                {
                                    textAlign: 'center',
                                    textTransform: 'uppercase'
                                }}
                        >la nostra rete</h5>
                        <hr className="rete-hr"></hr>
                        <p>Come detto, la forza di Antea risiede nell’<strong>ampia rete di qualificati professionisti del settore</strong>, 
                            in grado di soddisfare ogni bisogno del cliente. Un insieme ampio e diversificato di professionisti accomunati 
                            dalla passione per la tecnologia e l’innovazione e dall’essere improntati all’evoluzione, alla contaminazione e 
                            all’aggiornamento senza sosta. Esperti di comunicazione e digital marketing, di grafica e di software, di burocrazia e 
                            amministrazione: tutti insieme collaborano per garantire il migliore servizio possibile al cliente.
                        </p>
                        <br></br>
                        <p>
                            Non bisogna mai dimenticare che al centro di tutto ci sono le <strong>persone</strong>. Noi di Antea vogliamo instaurare con i clienti un 
                            rapporto proficuo e duraturo, basato sulla <strong>fiducia</strong> e sul <strong>rispetto reciproco</strong>.
                        </p>
                        <br></br>
                        <p>
                            Potete venire a trovarci nella nostra sede fisica a <strong>Milano</strong>, ma ci piace pensare che il posto a cui apparteniamo siano i meandri della rete, 
                            questa volta digitale, che contribuiamo a creare. Per questo non dovete preoccuparvi se vi trovate in città o in Paesi lontani; 
                            online ci troverete sempre e online noi di Antea vi offriremo sempre il nostro supporto!
                        </p>
                    </div>
                </div>
                <GoToContact/>
            </div>
        )
    }
}

export default About;