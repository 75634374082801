import React, { Component } from 'react';

import {
  BrowserRouter as Router,
    Route,
    Switch,
    Link,
    NavLink,
    withRouter
} from "react-router-dom";

import SectionButton from "./SectionButton";
import Button from 'react-bootstrap/Button';


import './Footer.scss'

import logo from '../assets/images/logo.svg';

class Footer extends Component {
    render() {
        return (
            <Router>
                <div className="footer-section">
                    <div className="row footer-container">
                        <div className="container col-md-4 footer-column left">
                            <div className="logo-container">
                                <img
                                style={{width: "200px", height:"76px"}}
                                    className="footer-logo"
                                    src={logo}
                                    alt="logo"
                                ></img>
                                <hr></hr>
                            </div>
                            <div className="text-container">
                                <p>Antea srl - p.iva 09851280967</p>
                                <br></br>
                                <h6>dove siamo</h6>
                                <hr></hr>
                                <p>via Stradella, 13 - 20129 Milano (MI)</p>
                                <p>Online: dove e quando vuoi!</p>
                            </div>                            
                        </div>
                        <div className="container col-md-4 footer-column center">
                            <div className="text-container">
                                <h6>esplora</h6>
                                <hr></hr>
                                <Button variant="link" href="#/About">Chi siamo</Button><br/>
                                <Button variant="link" href="#/services/AppServices">Sviluppo app mobile</Button><br/>
                                <Button variant="link" href="#/services/AppServices">Sviluppo software desktop</Button><br/>
                                <Button variant="link" href="#/services/WebServices">Sviluppo siti web</Button><br/>
                                <Button variant="link" href="#/services/GraphicProject">Grafica e progettazione</Button><br/>
                                <Button variant="link" href="#/services/MarketingCommunication">Marketing e comunicazione</Button><br/>
                                <Button variant="link" href="#/services/Administrative">Supporto amministrativo</Button>
                            </div>
                        </div>
                        <div className="container col-md-4 footer-column right">
                            <div className="text-container">
                                <h6>area legale</h6>
                                <hr></hr>
                                <p>Privacy e policy</p>
                                <p>Cookies</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>

        )
    }
}

export default Footer;