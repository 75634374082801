import React, { Component } from 'react';

import {
    BrowserRouter as Router
} from 'react-router-dom'

import './Services.scss';
import GoToContact from "../GoToContact";
import Aphorism from '../Aphorism';
import ServiceComponent from './ServiceComponent';


import design_responsive_bg from '../../assets/images/graph-background-3.jpg';
import brand_identity_bg from '../../assets/images/graph-background-5.jpg';
import loghi_grafiche_bg from '../../assets/images/graph-background-4.jpg';


import illustrator_icon from '../../assets/images/tech_icons/illustrator-icon.png';
import photoshop_icon from '../../assets/images/tech_icons/photoshop-icon.png';
import indesign_icon from '../../assets/images/tech_icons/indesign-icon.png';
import after_effects_icon from '../../assets/images/tech_icons/after-effects-icon.png';
import adobexd_icon from '../../assets/images/tech_icons/adobexd-icon.png';
import qgis_icon from '../../assets/images/tech_icons/qgis-icon.jpg';

class GraphicProject extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <Router>
                <div className="services-title-container">
                    <h1>grafica</h1>
                </div>
                <Aphorism
                    aphorism='"Non ho fallito. Ho solo trovato 10.000 modi che non funzionano"'
                    author="Thomas Edison"
                ></Aphorism>
                <ServiceComponent
                    title={<h3>ux-ui per un design responsive</h3>}
                    desc={
                        [
                            <p>Per essere efficaci al 100% app e siti web devono essere <strong>fruiti comodamente</strong> su 
                                tutti i dispositivi disponibili (pc, tablet e smartphone).</p>,
                            <br></br>,
                            <p>I designer della rete di Antea sono in grado di fare convergere il lato grafico ed estetico del 
                                prodotto (<strong>UI- User Interface</strong>) garantendo allo stesso tempo all'utente un'esperienza 
                                di utilizzo del sito o dell'app semplice ed intuitiva su tutti i dispositivi in possesso (<strong>UX- User 
                                Experience</strong>).</p>,
                            <br></br>,
                            <p>Dalla scelta del <strong>colore</strong> alla dimensione dei <strong>testi</strong> e delle <strong>immagini </strong> 
                              fino alla posizione dei pulsanti: questi studi sono fondamentali  per fornire all’utente un’<strong>esperienza di navigazione 
                                positiva</strong> ed evitare che l’utente possa abbandonare la piattaforma perchè scomoda da navigare e poco intuitiva.</p>
                        ]
                    }
                    src={design_responsive_bg}
                    className="row services-container-left"
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>brand identity</h3>}
                    desc={
                        [
                            <p>Antea crea e valorizza attraverso le immagini e i colori l'identità del brand. </p>,
                            <br></br>,
                            <p>La Brand Identity racchiude tutto l’universo concettuale di un marchio: gli elementi grafici,
                            il sistema di valori correlato all’azienda e le peculiarità che la contraddistinguono. 
                            In altre parole, ciò che viene percepito dai consumatori a livello emotivo e istintivo.</p>,
                            <br></br>,
                            <p>In un mondo sempre più digitale, Antea aiuta la tua azienda a rinnovarsi, digitalizzarsi 
                            e diventare finalmente competitiva anche nel terzo millennio tramite strategie mirate alla 
                            <strong> trasformazione tecnologica del brand</strong>.</p>
                        ]
                    }
                    src={brand_identity_bg}
                    className="row services-container-right"
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>loghi e grafiche personalizzate</h3>}
                    desc={
                        [
                            <p>Il successo di un marchio lo si deve anche a un <strong>logo</strong> in grado di catturare dopo una sola e fugace occhiata l'attenzione dei potenziali clienti. </p>,
                            <br></br>,
                            <p>La scelta e la creazione di un logo possono all’apparenza sembrare procedimenti facili e veloci. E’ invece un processo complesso che richiede un 
                            accurato studio del brand, dei suoi valori e del mercato in cui opera per essere certi di utilizzare forme, grafiche e colori che rispecchino fedelmente 
                            ciò che si desidera comunicare.</p>,
                            <br></br>,
                            <p>I grafici di Antea creano loghi e <strong>grafiche personalizzate</strong> e personalizzabili in grado di dare lustro o fare rinascere il brand. Le aziende oggi hanno
                             un continuo bisogno di produrre contenuti multimediali, che siano semplici grafiche o video più complessi, in modo da poterli veicolare sui propri 
                             canali di comunicazione (web, social) per raggiungere l’attenzione dell’utente. </p>,
                            <br></br>,
                            <p>Ci occupiamo di realizzare per i nostri clienti questi contenuti, affiancando ai nostri creativi team di analisti che studiano i consumatori e il mercato.</p>
                        ]
                    }
                    src={loghi_grafiche_bg}
                    className="row services-container-left"
                ></ServiceComponent>
                <div className="row services-container-right">                    
                    <div className="container text-container col-lg-6 ">
                        <h3>technical</h3>
                        <hr className="services-divider"></hr>   
                        <ul>
                            <li>UX-UI management</li>
                            <br></br>
                            <li>Design responsive</li>
                            <br></br>
                            <li>Brand identity</li>
                            <br></br>
                            <li>Logo e grafica personalizzata</li>
                            <br></br>
                            <li>Illustrator</li>
                            <br></br>
                            <li>Photoshop</li>
                            <br></br>
                            <li>Indesign</li>
                            <br></br>
                            <li>After Effects</li>
                            <br></br>
                            <li>Adobe XD</li>
                            <br></br>
                            <li>QGIS</li>
                            <br></br>
                        </ul>
                    </div>
                    <div className="container col-lg-6 icons-table icons-table--3cols">
                        <div className="icons-table-cell">
                            <img src={ illustrator_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ photoshop_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ indesign_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <img src={ after_effects_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ adobexd_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ qgis_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                    </div>
                </div>
                <GoToContact/>
            </Router>
        )
    }
}

export default GraphicProject;