import React, { Component } from 'react';

import {
    BrowserRouter as Router
} from 'react-router-dom'

import './Services.scss';
import GoToContact from "../GoToContact";
import Aphorism from '../Aphorism';
import ServiceComponent from './ServiceComponent';

import app_background_1 from '../../assets/images/app-background-1.jpg';
import app_background_2 from '../../assets/images/app-background-2.jpg';
import app_background_3 from '../../assets/images/app-background-3.jpg';

import java_icon from '../../assets/images/tech_icons/java-icon.png';
import kotlin_icon from '../../assets/images/tech_icons/kotlin-icon.png';
import objective_c_icon from '../../assets/images/tech_icons/objective-c-icon.png';
import swift_icon from '../../assets/images/tech_icons/swift-icon.png';
import java_swing_icon from '../../assets/images/tech_icons/java-swing-icon.png';
import microsoft_wpf_icon from '../../assets/images/tech_icons/microsoft-wpf-icon.png';
import xamarin_icon from '../../assets/images/tech_icons/xamarin-icon.png';
import react_native_icon from '../../assets/images/tech_icons/react-native-icon.png';

class AppServices extends Component {

componentDidMount() {
    window.scrollTo(0, 0);
}

    render() {
        return (
            <Router>
                <div className="services-title-container">
                    <h1>sviluppo software</h1>
                </div>
                <Aphorism
                    aphorism="Il computer è straordinariamente accurato, veloce e stupido.
                            L'uomo è incredibilmente lento, impreciso e creativo.
                            L'insieme dei due costituisce una forza incalcolabile"
                    author="Albert Einstein"
                ></Aphorism>
                <ServiceComponent
                    title={<h3>applicazioni ios e android</h3>}
                    desc={
                        [
                            <p>Le applicazioni mobili sono diventate uno <strong>strumento imprescindibile</strong> per la nostra quotidianità, sia in ambito privato che in quello lavorativo.</p>,
                            <br></br>,
                            <p>Il concetto fondamentale nell’utilizzo di ogni tecnologia è che è la tecnologia stessa che deve essere adattata al soddisfacimento di una necessità, non il contrario;</p>,
                            <br></br>,
                            <p>ANTEA si occupa quindi dello sviluppo del software partendo dalle domande basilari “Di cosa ho bisogno?” e “Che cosa voglio ottenere?”, ottimizzando in tal modo tutta 
                                l’architettura del programma ed evitando quindi aggiunte inutili e costose.</p>,
                            <br></br>,
                            <p>Siamo in grado di programmare sui sistemi operativi <strong>iOS</strong> e <strong>Android</strong> sia con due codici distinti sia utilizzando framework ibridi, quali <strong>React Native</strong> o <strong>Xamarin</strong>, 
                                partendo dall’idea di base oppure integrando applicazioni già esistenti.</p>
                        ]
                    }
                    src={app_background_1}
                    className="row services-container-left"
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>software per desktop</h3>}
                    desc={
                        [
                            <p>I software per desktop sono forse ora quelli meno conosciuti - o riconosciuti - anche se li utilizziamo tendenzialmente tutti i giorni.</p>,
                            <br></br>,
                            <p>Esempio di software di questo tipo sono, la calcolatrice il visualizzatore di immagini, Paint, Word, Excel e tantissimi altri.</p>,
                            <br></br>,
                            <p>Altri software sono decisamente più complessi, dal punto di vista dell’utilizzatore, come quelli gestionali personalizzati con le 
                                funzionalità aziendali, oppure i videogiochi o programmi di grafica.</p>,
                            <br></br>,
                            <p>ANTEA sviluppa programmi per <strong>PC</strong> e <strong>MacBook</strong>, integrabili con piattaforme esterne (ad esempio applicazioni Android, iOS, web app, 
                                database, etc.) o standalone, sempre su misura delle necessità del Cliente.</p>
                        ]
                    }
                    src={app_background_2}
                    className="row services-container-right"
                ></ServiceComponent>
                {/*<ServiceComponent
                    title={<h3>iot - internet of things</h3>}
                    desc={
                        [
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>,
                            <br></br>,
                            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>,
                            <br></br>,
                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>,
                            <br></br>,
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        ]                        
                    }
                    src={app_background_3}
                    className="row services-container-left"
                ></ServiceComponent>*/}
                <div className="row services-container-right">                    
                    <div className="container text-container col-lg-6 ">
                        <h3>technical</h3>
                        <hr className="services-divider"></hr>   
                        <ul>
                            <li>Sviluppo iOS e Android</li>
                            <br></br>
                            <li>Sviluppo multipiattaforma - Xamarin</li>
                            <br></br>
                            <li>Sviluppo ibrido - React Native</li>
                            <br></br>
                            <li>Frontend e Backend</li>
                            <br></br>
                            <li>Consulenza</li>
                            <br></br>
                            <li>Test e debug</li>
                            <br></br>
                            <li>Integrazione e aggiornamento</li>
                        </ul>
                    </div>
                    <div className="container col-lg-6 icons-table icons-table--3cols">
                        <div className="icons-table-cell">
                            <h4>android</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ java_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ kotlin_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <h4>ios</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ objective_c_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ swift_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <h4>desktop</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ java_swing_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ microsoft_wpf_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <h4>hybrid</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ xamarin_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ react_native_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                    </div>
                </div>
                <GoToContact/>
            </Router>

        )
    }
}

export default AppServices;