import React, { Component } from 'react';

import {
    BrowserRouter as Router
} from 'react-router-dom'

import './Services.scss';
import Aphorism from '../Aphorism';
import ServiceComponent from './ServiceComponent';
import GoToContact from "../GoToContact";

import web_background_1 from '../../assets/images/web-background-1.jpg';
import web_background_2 from '../../assets/images/web-background-2.jpg';
import web_background_3 from '../../assets/images/web-background-3.jpg';

import jquery_icon from '../../assets/images/tech_icons/jquery-icon.jpg';
import angular_icon from '../../assets/images/tech_icons/angular-icon.png';
import html5_c_icon from '../../assets/images/tech_icons/html5-icon.png';
import javascript_icon from '../../assets/images/tech_icons/javascript-icon.png';
import react_icon from '../../assets/images/tech_icons/react-icon.png';
import bootstrap_icon from '../../assets/images/tech_icons/bootstrap-icon.png';
import microsoftnet_icon from '../../assets/images/tech_icons/microsoftnet-icon.png';
import nodejs_icon from '../../assets/images/tech_icons/nodejs-icon.png';
import php_icon from '../../assets/images/tech_icons/php-icon.png';
import mysql_icon from '../../assets/images/tech_icons/mysql-icon.png';
import microsoftsql_icon from '../../assets/images/tech_icons/microsoftsql-icon.png';
import mongodb_icon from '../../assets/images/tech_icons/mongodb-icon.png';
import wordpress_icon from '../../assets/images/tech_icons/wordpress-icon.png';
import prestashop_icon from '../../assets/images/tech_icons/prestashop-icon.png';
import shopify_icon from '../../assets/images/tech_icons/shopify-icon.png';

class WebServices extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <Router>
                <div className="services-title-container">
                    <h1>sviluppo web</h1>
                </div>
                <Aphorism
                    aphorism='"Un sito web è come la vetrina di un negozio ma senza polvere e con meno tasse."'
                    author="Anonimo"
                ></Aphorism>
                <ServiceComponent
                    title={<h3>siti vetrina</h3>}
                    desc={
                        [
                            <p>I siti definiti <strong>“vetrina”</strong> sono esattamente quello che sembrano, ovvero l’esposizione al pubblico dell’immagine 
                                dell’azienda, con la sua storia, il suo stile, la sua mission e i suoi prodotti. </p>,
                            <br></br>,
                            <p>Un negozio non presente sul web si scontra necessariamente con un ridotto bacino di potenziali clienti, 
                                nonché con una concorrenza tecnologicamente più avanzata.</p>,
                            <br></br>,
                            <p>Questa differenza, magari inizialmente non percepita come problema, non potrà che ampliarsi con il tempo se 
                                non ci si adatta ai mutamenti dell’economia e del mercato che spingono per un commercio sempre più 
                                tecnologizzato.</p>,
                            <br></br>,
                            <p>Contrariamente da com’era alla loro nascita, ora i siti web sono diventati accessibili a chiunque.</p>,
                            <br></br>,
                            <p>ANTEA propone quindi soluzioni web <strong>personalizzate</strong> ad hoc e create da zero, oppure soluzioni CMS (<strong>Wordpress</strong>), 
                                più semplici da gestire e meno costose ma meno personalizzabili.</p>
                        ]
                    }
                    src={web_background_1}
                    className="row services-container-left"
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>e-commerce</h3>}
                    desc={
                        [
                            <p>Un sito <strong>e-commerce</strong>  è lo strumento ideale per qualsiasi realtà che voglia vendere senza limiti temporali e senza 
                                limiti territoriali.Possedere un e-commerce è diventato necessario se si vuole fare crescere il proprio business 
                                evitando il ristagno e, inevitabilmente, la chiusura. Grazie ad esso potrai vendere anche online, garantendo ai 
                                tuoi clienti la stessa qualità del negozio fisico. </p>,
                            <br></br>,
                            <p>Antea sviluppa siti e-commerce multilingua, intuitivi ed efficaci, in grado di integrarsi perfettamente con il 
                                negozio fisico ed aumentare così le vendite.</p>,
                            <br></br>,
                            <p>Non solo. La maggior parte dei consumatori italiani usa il <strong>cellulare per fare spese online</strong>. Viene da sé che è 
                                quindi importantissimo pensare la propria presenza online anche in quest’ottica, per intercettare e soddisfare 
                                questa ampia fetta di utenti. Avere un <strong>e-commerce ottimizzato mobile</strong> significa dare la possibilità agli utenti di 
                                <strong>acquistare in ogni momento</strong>  e di risparmiare tempo. Per chi possiede uno <strong>shop online</strong> significa <strong>aumentare</strong> le possibilità 
                                e le probabilità di vendita, stimolando l’acquisto in qualsiasi orario e luogo. </p>,
                            <br></br>,
                            <p>ANTEA utilizza sistemi personalizzati o i migliori software disponibili per sviluppare al meglio il proprio e-commerce 
                                come ad esempio <strong>Shopify</strong>, <strong>Prestashop</strong> o <strong>Magento</strong>.</p>,
                        ]
                    }
                    src={web_background_2}
                    className="row services-container-right"
                ></ServiceComponent>    
                <ServiceComponent
                    title={<h3>web app</h3>}
                    desc={
                        [
                            <p>Le applicazioni <strong>Web Based</strong>, o <strong>web app</strong>, sono applicativi accessibili direttamente tramite web, ossia software che non necessitano 
                                di essere installati sul pc, non riscontrando dunque i vincoli del caso. Non si tratta di software che risiedono fisicamente 
                                nel vostro computer, ma sono raggiungibili attraverso internet da qualsiasi terminale o dispositivo. </p>,
                            <br></br>,
                            <p> Inoltre gli aggiornamenti sono automatici e non richiedono alcun tipo di installazione e questo permette un cospicuo risparmio
                                 di tempo e denaro rispetto alle “classiche app”. I dati vengono salvati direttamente nel cloud e sono sempre disponibili.</p>,
                            <br></br>,
                            <p>Antea garantisce  lo sviluppo di Web Application personalizzate, responsive su ogni dispositivo e altamente performanti. </p>
                        ]
                    }
                    src={web_background_3}
                    className="row services-container-left"
                ></ServiceComponent>   
                <div className="row services-container-right">
                    <div className="container text-container col-lg-6 right">
                        <h3>technical</h3>
                        <hr className="services-divider"></hr>   
                        <ul>
                            <li>Single page apps</li>
                            <br></br>
                            <li>Multi page website</li>
                            <br></br>
                            <li>Siti statici e dinamici</li>
                            <br></br>
                            <li>Frontend e Backend</li>
                            <br></br>
                            <li>Portfolio e blog</li>
                            <br></br>
                            <li>Forum</li>
                            <br></br>
                            <li>CMS</li>
                            <br></br>
                            <li>E-commerce</li>
                            <br></br>
                            <li>Search engine</li>
                            <br></br>
                            <li>Portali web</li>
                            <br></br>
                            <li>Social network</li>
                            <br></br>
                            <li>Consulenza</li>
                            <br></br>
                            <li>Test e debug</li>
                            <br></br>
                            <li>Integrazione e aggiornamento</li>
                        </ul>
                    </div>
                    <div className="container col-lg-6 icons-table icons-table--4cols">
                        <div className="icons-table-cell">
                            <h4>frontend</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ jquery_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ angular_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ html5_c_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ javascript_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ react_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ bootstrap_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <h4>backend</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ microsoftnet_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ nodejs_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ php_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <h4>database</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ mysql_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ microsoftsql_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ mongodb_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <h4>CMS</h4>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ wordpress_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ prestashop_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ shopify_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                    </div>
                    <GoToContact/>
                </div>
            </Router>
        )
    }
}

export default WebServices;