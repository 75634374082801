import React, {Component} from 'react';

import { Dropdown, Menu } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

class InlineMenu extends Component {
    render() {

        let services = [
            '/services/AppServices',
            '/services/WebServices',
            '/services/GraphicProject',
            '/services/MarketingCommunication',
            '/services/Administrative'
        ]

        return (
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <Menu borderless floated="right" className="menu right">
                    <Menu.Item className="menu-items" href='/'><h5>home</h5></Menu.Item>
                    <Menu.Item className="menu-items" href='../#/About'><h5>chi siamo</h5></Menu.Item>
                    <Dropdown  text={<h5>servizi</h5>} className="link item menu-items">
                        <Dropdown.Menu className="dropdown-items">
                            <Link to={services[0]}><Dropdown.Item><p>Sviluppo software</p></Dropdown.Item></Link>
                            <Dropdown.Divider className="dropdown-divider"></Dropdown.Divider>
                            <Link to={services[1]}><Dropdown.Item><p>Sviluppo siti web</p></Dropdown.Item></Link>
                            <Dropdown.Divider className="dropdown-divider"/>
                            <Link to={services[2]}><Dropdown.Item><p>Grafica</p></Dropdown.Item></Link>
                            <Dropdown.Divider className="dropdown-divider"/>
                            <Link to={services[3]}><Dropdown.Item><p>Marketing e comunicazione</p></Dropdown.Item></Link>
                            <Dropdown.Divider className="dropdown-divider"/>
                            <Link to={services[4]}><Dropdown.Item><p>Supporto amministrativo</p></Dropdown.Item></Link>
                            <Dropdown.Divider className="dropdown-divider"/>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item className="menu-items" href='../#/Contatti'><h5>contatti</h5></Menu.Item>
                </Menu>                    
            </div>
        )
    }
}

export default InlineMenu;