import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter
} from "react-router-dom";

import './Home.scss';
import GoToContact from "./GoToContact";
import SectionButton from "./SectionButton";

import insegna_background from '../assets/images/insegna-background.jpg';
import mobile_dev_background from '../assets/images/mobile-dev-background.jpg';
import web_dev_background from '../assets/images/web-dev-background.jpg';
import graph_project_background from '../assets/images/graph-project-background.jpg';
import marketing_com_background from '../assets/images/marketing-com-background.jpg';
import administrative_background from '../assets/images/administrative-background.jpg';

class Home extends Component {
    render() {
        let app_page = "/";
        let content = "";
        return (
            <Router>
                <div className="home-row">
                    <div className="home">
                        <div className="container title-section">
                            <div className="row title-container" style={{ backgroundImage: `url(${insegna_background})` }}>
                                <div className="title">
                                    <h1>antea tech</h1>
                                    <h4>professional software development<br/>for your digital success</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container left-text-section">
                            <div className="row left-text-container" style={{ backgroundImage: `url(${mobile_dev_background})` }}>
                                <div className="text container col-sm-12">
                                    <div className="left-text-description container col-sm-5">
                                        <h5><strong>sviluppo mobile app</strong></h5>
                                        <p>Ci occupiamo dello sviluppo software su tutti i principali dispositivi utilizzati attualmente.
                                            <br /> <br/>
                                            Lo sviluppo di applicazioni mobili, le cosiddette “app”, è il servizio maggiormente richiesto sul mercato perché consente di raggiungere i propri clienti in ogni momento, ovunque si trovino.
                                            <br /> <br/>
                                            ANTEA ti permette di dare vita alla tua app su qualsiasi dispositivo – smartphone, tablet e smartwatch – sia per iOs che per Android.
                                        </p>
                                    <SectionButton page="#/services/AppServices"></SectionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container right-text-section">
                            <div className="row right-text-container right-text-overlay" style={{ backgroundImage: `url(${web_dev_background})` }}>
                                <div className="text container col-sm-12">
                                    <div className="right-text-description container col-sm-5">
                                        <h5>sviluppo siti web</h5>
                                        <p>Al giorno d’oggi possedere un sito web è un prerequisito indispensabile per chiunque voglia intraprendere un’attività. 
                                            <br /> <br/>
                                            Che si tratti di un e-commerce o di un sito vetrina, la pagina web rappresenta nella maggior parte dei casi il primo incontro tra un potenziale cliente e i servizi offerti.
                                            <br /> <br/>
                                            ANTEA sviluppa i siti web su misura del cliente, ponendo particolare attenzione al messaggio da comunicare e alle sensazioni da trasmettere.
                                        </p>
                                        <SectionButton page="#/services/WebServices"></SectionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container left-text-section">
                            <div className="row left-text-container" style={{ backgroundImage: `url(${graph_project_background})` }}>
                                <div className="text container col-sm-12">
                                    <div className="left-text-description container col-sm-5">
                                        <h5>Grafica</h5>
                                        <p>Anche l'occhio vuole la sua parte!
                                            <br /> <br/>
                                            Una comunicazione efficace passa necessariamente da una veste grafica accattivante in grado di catturare l'occhio dello spettatore.
                                            <br /> <br/>
                                            Ci sono delle vere e proprie materie scientifiche, come ad esempio la psicologia dei colori, che studiano le combinazioni grafiche più adatte per supportare un brand o un prodotto affinché possano rimanere impresse nella testa di chi guarda.
                                        </p>
                                    <SectionButton page="#/services/GraphicProject"></SectionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container right-text-section">
                            <div className="row right-text-container right-text-overlay" style={{ backgroundImage: `url(${marketing_com_background})` }}>
                                <div className="text container col-sm-12">
                                    <div className="right-text-description container col-sm-5">
                                        <h5>Marketing e comunicazione</h5>
                                        <p>Implementare una strategia di comunicazione e marketing mirata è necessaria e fondamentale per fare crescere le performance del proprio brand o prodotto, aumentare le vendite e rafforzare la brand awereness.
                                            <br /> <br/>
                                            Grazie alla sua estesa rete di professionisti, Antea si avvale dei migliori marketer in grado di elaborare campagne di marketing vincenti.
                                        </p>
                                        <SectionButton page="#/services/MarketingCommunication"></SectionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container left-text-section">
                            <div className="row left-text-container" style={{ backgroundImage: `url(${administrative_background})` }}>
                                <div className="text container col-sm-12">
                                    <div className="left-text-description container col-sm-5">
                                        <h5>supporto amministrativo</h5>
                                        <p>Ogni attività deve interfacciarsi con sistematiche barriere burocratiche, soprattutto all’inizio. 
                                            <br /> <br/>
                                            ANTEA può fornire supporto specifico in questo ambito grazie alla stretta collaborazione con professionisti del settore, permettendo ai propri clienti di dedicarsi interamente alla propria attività. 
                                            <br /> <br/>
                                            Che sia l’apertura della partita iva, l’inizio dell’attività o la tenuta della contabilità, questo può diventare un fondamentale sostegno per chi si sente perso nel labirinto della burocrazia.
                                        </p>
                                    <SectionButton page="#/services/Administrative"></SectionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GoToContact/>
                    </div>
                </div>
            </Router>
        )
    }
}

export default Home;