import React, { Component } from 'react';

import {
    BrowserRouter as Router, 
    Route,
    HashRouter
} from 'react-router-dom'

import './Contatti.scss';

class Contatti extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div>
                <div className="contacts-title-container">
                    <h1>contattaci</h1>
                </div>
                <div className="container contacts-introduction-section">

                </div>
                <div className="row contacts-introduction">
                    <div className="container contacts-text col-md-6">
                        <p>
                            Contattaci per un preventivo senza impegno o per un incontro conoscitivo. 
                            Siamo sempre pronti a seguire il tuo progetto con la massima flessibilità 
                            possibile, intervenendo "chirurgicamente" sulle varie componenti.
                        </p>
                        <hr className="contacts-text-hr"></hr>
                        <p>Scrivi a <a href="mailto:info@anteatech.it">info@anteatech.it</a> oppure chiamaci al numero <a href="tel:+39 334 862 4893">+39 334 862 4893</a></p>
                    </div>
                    <div className="container contacts-aphorism-container col-md-6">
                        <p className="contacts-aphorism">
                            "Il piacere più grande nella vita è fare ciò che le persone dicono che non puoi fare."
                        </p>
                        <p className="contacts-aphorism-author">
                            Walter Bagehot
                        </p>
                    </div>
                </div>
                <div className="container address-section">
                    <div className="row address">
                        <div className="container address-text-container col-md-5">
                            <div className="address-text-title">
                                <h6>dove siamo</h6>
                            </div>
                            <hr className="address-hr"></hr>
                            <div className="address-text">
                                <p>via Stradella, 13</p>
                                <br/>
                                <p>20129 - Milano (MI)</p>
                            </div>
                        </div>
                        <div className="container google-map-container col-md-7">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11190.844048019791!2d9.214336724760333!3d45.47562509027514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6ebe9725cf9%3A0x71b206022135cd26!2sVia%20Alessandro%20Stradella%2C%2013!5e0!3m2!1sit!2sit!4v1615926899183!5m2!1sit!2sit"
                            allowFullScreen="" 
                            loading="lazy"
                            className="google-map"
                        ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contatti;