import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import { Dropdown, Menu } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import './BurgerMenu.scss'

class BurgerMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {open: false};
    


        // Necessario per accedere al corretto valore di `this` all'interno della callback
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
    
        if (!domNode || !domNode.contains(event.target)) {
            this.setState(state => ({
                open: !state.open
            }));
        }
    }

    handleClick() {


        this.setState(state => ({
            open: !state.open
        }));
    }

    render() {

        let services = [
            '/services/AppServices',
            '/services/WebServices',
            '/services/GraphicProject',
            '/services/MarketingCommunication',
            '/services/Administrative'
        ]

        console.log(this.state);

        return (
            <div>
                <div className={"burger-menu " + (this.state.open? "change":"")} onClick={this.handleClick}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <Menu vertical borderless floated="right" className={"menu right " + (this.state.open? "":"hidden")}>
                        <Menu.Item className="menu-items" href='/'><h5>home</h5></Menu.Item>
                        <Menu.Item className="menu-items" href='../#/About'><h5>chi siamo</h5></Menu.Item>
                        <Dropdown closeOnChange={true} text={<h5>servizi</h5>} className="link item menu-items">
                            <Dropdown.Menu className="dropdown-items" direction={'left'} >
                                <Dropdown.Item onClick={this.handleClick}><Link to={services[0]}><p>Sviluppo software</p></Link></Dropdown.Item>
                                <Dropdown.Divider className="dropdown-divider"></Dropdown.Divider>
                                <Dropdown.Item onClick={this.handleClick}><Link to={services[1]}><p>Sviluppo web</p></Link></Dropdown.Item>
                                <Dropdown.Divider className="dropdown-divider"/>
                                <Dropdown.Item onClick={this.handleClick}><Link to={services[2]}><p>Grafica</p></Link></Dropdown.Item>
                                <Dropdown.Divider className="dropdown-divider"/>
                                <Dropdown.Item onClick={this.handleClick}><Link to={services[3]}><p>Marketing e comunicazione</p></Link></Dropdown.Item>
                                <Dropdown.Divider className="dropdown-divider"/>
                                <Dropdown.Item onClick={this.handleClick}><Link to={services[4]}><p>Supporto amministrativo</p></Link></Dropdown.Item>
                                <Dropdown.Divider className="dropdown-divider"/>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Menu.Item className="menu-items" href='../#/Contatti'><h5>contatti</h5></Menu.Item>
                    </Menu>   
                </div>
            </div>
            
            
        )
    }
}

export default BurgerMenu;