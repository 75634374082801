import React, { Component } from 'react';

import {
    BrowserRouter as Router
} from 'react-router-dom'
    
import './Services.scss';
import GoToContact from "../GoToContact";
import Aphorism from '../Aphorism';

import mark_com_background_1 from '../../assets/images/mark-com-background-4.jpg';
import mark_com_background_2 from '../../assets/images/mark-com-background-2.jpg';
import mark_com_background_3 from '../../assets/images/mark-com-background-3.jpg';
import mark_com_background_4 from '../../assets/images/mark-com-background-1.jpg';
import ServiceComponent from './ServiceComponent';

import facebook_icon from '../../assets/images/tech_icons/facebook-icon.png';
import instagram_icon from '../../assets/images/tech_icons/instagram-icon.jpg';
import twitter_icon from '../../assets/images/tech_icons/twitter-icon.png';
import tiktok_icon from '../../assets/images/tech_icons/tiktok-icon.png';
import whatsapp_icon from '../../assets/images/tech_icons/whatsapp-icon.png';
import linkedin_icon from '../../assets/images/tech_icons/linkedin-icon.png';
import google_icon from '../../assets/images/tech_icons/google-icon.png';
import email_icon from '../../assets/images/tech_icons/email-icon.png';
import pinterest_icon from '../../assets/images/tech_icons/pinterest-icon.png';

class MarketingCommunication extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <Router>
                <div className="services-title-container">
                    <h1>marketing e comunicazione</h1>
                </div>
                <Aphorism
                    aphorism='"Non è la specie più forte a sopravvivere, ma quella che si adatta meglio al cambiamento"'
                    author="Charles Darwin"
                ></Aphorism>
                <ServiceComponent
                    title={<h3>analisi e definizione della strategia</h3>}
                    desc={
                        [
                            <p>Eseguire un'<strong>analisi</strong> del proprio <strong>brand</strong>, del mercato in cui si opera e dei propri <strong>competitor</strong> è 
                            il primo passo della creazione di una strategia di marketing efficace. In questa fase, 
                            ipotizziamo il <strong>target</strong> di riferimento del progetto e le eventuali criticità presenti.</p>,
                            <br></br>,
                            <p>Fatte le ipotesi, passiamo a validarle: attraverso approfondite analisi aiutati dai migliori tool presenti sul mercato e <strong>ricerche</strong> sul campo, 
                            identifichiamo i <strong>bisogni</strong> del target, li caliamo nel contesto di riferimento, ne prevediamo i comportamenti e agiamo di conseguenza. 
                            Grazie a queste valutazioni i nostri marketer saranno in grado di capire quali canali e quali strumenti utilizzare per entrare in sintonia con i potenziali clienti.</p>,
                            <br></br>,
                            <p>A seguito di questa fase preliminare, andiamo ad analizzare l’esperienza, trasformando le precedenti ipotesi in output più concreti. 
                            Cercando di entrare in contatto con il target di riferimento accuratamente selezionato in precedenza e, per farlo al meglio, definiamo le nostre <strong>“personas”</strong>.</p>,
                            <br></br>,
                            <p>Questo ci permette di rappresentare, attraverso l’ideazione di profili verosimili, le necessità, le abitudini, le ambizioni e gli atteggiamenti dei nostri utenti.</p>
                        ]
                    }
                    src={mark_com_background_1}
                    className="row services-container-left"                
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>social media marketing</h3>}
                    desc={
                        [
                            <p>Elaboriamo  piani editoriali e contenuti per i <strong>social media</strong>, finalizzati a comunicare i valori del brand, a incrementarne la <strong>visibilità</strong> su utenti in target e a trasformare i followers in lead. </p>,
                            <br></br>,
                            <p>Creiamo post di qualità, focalizzati su elementi grafici e contenutistici strategici, con l’obiettivo di accrescere progressivamente interazione, audience e connessioni.</p>,
                            <br></br>,
                            <p><strong>Monitoriamo costantemente</strong> i risultati delle campagne in base a KPI stabiliti in fase strategica, per fornire costantemente report trasparenti e dettagliati ai clienti.</p>,
                            <br></br>,
                            <p>I professionisti della nostra rete sono sempre attenti all'evoluzione dei social media per cercare la piattaforma migliore per sviluppare il brand.</p>,
                            <br></br>,
                            <p>Creiamo campagne su tutti i principali social media, come Facebook, Instagram, Linkedin e Tik Tok.</p>
                        ]
                    }
                    src={mark_com_background_2}
                    className="row services-container-right"                
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>marketing sui motori di ricerca</h3>}
                    desc={
                        [
                            <p>Offriamo consulenze mirate per l'implementazione di <strong>campagne di web marketing efficaci</strong>, in grado di potenziare le performance del proprio sito
                             e aumentare le vendite dei prodotti. Operiamo anche su <strong>Google ADS</strong> e <strong>Google My Business</strong> al fine di adottare una strategia a 360 gradi. </p>,
                            <br></br>,
                            <p>Un sito web e un e-commerce possono avere svariati problemi che portano l’utente ad abbandonare la piattaforma e a rivolgersi ai competitor. Ad esempio, 
                            tempi di caricamento lunghi, navigazione non intuitiva, grafiche e testi poco accattivanti ed errori tecnici del sito portano Google a penalizzarlo nei risultati di ricerca. </p>,
                            <br></br>,
                            <p>Tramite appositi tool, i nostri specialisti <strong>SEO</strong> e Google ADS sono in grado di sistemare tutte queste problematiche e, di conseguenza  migliorare organicamente il posizionamento del sito e fare in modo che sia visualizzato nelle <strong>prime posizioni dei risultati di Google</strong>.</p>
                        ]
                    }
                    src={mark_com_background_4}
                    className="row services-container-left"                
                ></ServiceComponent>
                <ServiceComponent
                    title={<h3>e-mail marketing e sistemi di messaggistica automatizzata</h3>}
                    desc={
                        [
                            <p>Grazie alla marketing automation sviluppiamo campagne di marketing adatte per raggiungere in maniera rapida ed efficace <strong>migliaia di clienti in un solo click</strong>.</p>,
                            <br></br>,
                            <p>I professionisti della nostra rete sono anche maestri nel creare strategie di vendita alternative grazie alla creazione di <strong>sistemi di messaggistica istantanea</strong> su Facebook, Instagram e Whatsapp.</p>,
                            <br></br>,
                            <p>Tutte queste piattaforme sono diventate dei <strong>marketplace</strong>, delle vere e proprie vetrine dove poter vendere rapidamente i propri prodotti e avere un contatto 
                            diretto e “caldo” con il cliente, come in un negozio fisico, per poterlo supportare nel processo di acquisto e personalizzare la sua esperienza.</p>
                        ]
                    }
                    src={mark_com_background_3}
                    className="row services-container-right"
                ></ServiceComponent>
                <div className="row services-container-left">                    
                    <div className="container text-container col-lg-6 ">
                        <h3>technical</h3>
                        <hr className="services-divider"></hr>   
                        <ul>
                            <li>Analisi del brand, del mercato e dei competitor</li>
                            <br></br>
                            <li>Targeting</li>
                            <br></br>
                            <li>Monitoraggio delle campagne</li>
                            <br></br>
                            <li>Facebook</li>
                            <br></br>
                            <li>Instagram</li>
                            <br></br>
                            <li>Twitter</li>
                            <br></br>
                            <li>Linkedin</li>
                            <br></br>
                            <li>Tik Tok</li>
                            <br></br>
                            <li>Whatsapp</li>
                            <br></br>
                            <li>Google Ads e Google My Business</li>
                            <br></br>
                            <li>SEO e SEM</li>
                            <br></br>
                            <li>E-mail marketing</li>
                            <br></br>
                            <li>Pinterest</li>
                            <br></br>
                            <li>Messaggistica automatizzata</li>
                            <br></br>
                        </ul>
                    </div>
                    <div className="container col-lg-6 icons-table icons-table--3cols">
                        <div className="icons-table-cell">
                            <img src={ facebook_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ instagram_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ twitter_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <img src={ linkedin_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ tiktok_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ whatsapp_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                        <div className="icons-table-cell">
                            <img src={ google_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ pinterest_icon }></img>
                        </div>
                        <div className="icons-table-cell">
                            <img src={ email_icon }></img>
                        </div>
                        <hr className="icons-divider"></hr>
                    </div>
                </div>
                <GoToContact/>
            </Router>
        )
    }
}

export default MarketingCommunication;